<template>
  <div class="wrap" v-loading='loading'>
    <div class="fontBold fontS25 padd10 x-fc">
      <div>
        <span>物料名称:</span>
        <span class="annotateBlue">{{billInfo.goodsName}}</span>
      </div>
      <div class="marL20">
        <span>分货数:</span>
        <span class="annotateBlue">{{billInfo.unitQty}}</span>
      </div>
      <!-- <div class="marL20">
        <span>次品数:</span>
        <span class="annotateBlue">{{billInfo.inferiorQty}}</span>
      </div> -->
    </div>
    <div class="x-fc">
      <TablePage ref="tablePage" v-model="options1" @handleEvent="handleEvent" style="width:40%" />
      <TablePage ref="tablePage1" v-model="options2" @handleEvent="handleEvent" style="width:60%" />
    </div>

    <Dialog ref="tablePage2" :options.sync="dialogOptions" @handleEvent="handleEvent" />
  </div>
</template>

<script>
import Dialog from "@/components/Dialog";
import TablePage from "@/components/tablePage"; //表格页面组件
import {
  calculateDistribute,
  getCalculateDistributeDetail,
  modifyFactDistributeQty,
  resetDistribute,
  artificiallyAddDistributeObject,
  delDistributeObject
} from "@/api/produce/bill/autoDistribute"; //智能分货
import { deepCopy } from '@/utils';
export default {
  name: "AutoDisDialogtributeDetail",
  components: { Dialog, TablePage },
  data() {
    return {
      goodsInfo: {},
      billInfo: {
        goodsName: '',
        unitEligibleQty: 0,
        inferiorQty: 0,
      },
      autoDistributeStatus: 1,
      dialogOptions: {
        title: "要货加单",
        width: 1250,
        show: false,
        type: "AskGoodsAddOrders",
        goodsInfo: {
          goodsId: undefined,
          unitId: undefined,
          lineId: undefined,
        }
      },
      loading: false,
      options1: {
        radioSelect: true,
        loading: false,
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        hidePagination: true,
        // getListApi: listSmartDistribute,
        listNo: true, // 序号
        check: [], // 选中数据
        rowKey: "lineId",
        //按钮
        buttons: [
          {
            click: "submitForm",
            label: "保存",
            type: "primary",
            noRule: true,
            disabled: () => this.autoDistributeStatus == 3
          },
          {
            click: "calculate",
            label: "分货计算",
            type: "primary",
            alertText: "确认要进行分货计算吗？",
            noRule: true,
            disabled: () => this.autoDistributeStatus != 1
          },
          {
            click: "accomplish",
            label: "要货加单",
            type: "primary",
            noRule: true,
            disabled: () => this.autoDistributeStatus != 1
          },
          {
            click: "addDistribute",
            label: "新增分货对象",
            icon: "el-icon-plus",
            type: "primary",
            noRule: true,
            disabled: () => this.autoDistributeStatus != 1
          },
          {
            click: "delDistribute",
            label: "删除分货对象",
            icon: "el-icon-delete",
            type: "danger",
            noRule: true,
            disabled: () => this.autoDistributeStatus != 1
          },
          {
            click: "resetSaasDistribute",
            label: "重置",
            type: "primary",
            noRule: true,
            alertText: "确认要进行重置吗？",
            disabled: () => this.autoDistributeStatus != 2
          },
          {
            click: "getQuit",
            label: "退出",
            type: "primary",
            noRule: true,
          },
        ],
        //表格
        columns: [
          {
            prop: "lineNo",
            label: "路线编号",
            minWidth: 160,
            align: "center",
          },
          {
            prop: "lineName",
            label: "路线名称",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "orderQty",
            label: "订货总数",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "distributeQty",
            label: "分货总数",
            minWidth: 120,
            align: "center",
          },
        ],
        list: [],
      },
      options2: {
        mutiSelect: true,
        loading: false,
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        hidePagination: true,
        // getListApi: listSmartDistribute,
        listNo: true, // 序号
        check: [], // 选中数据
        rowKey: "clientId",
        //表格
        columns: [
          {
            prop: "clientNo",
            label: "分店/客户编号",
            minWidth: 150,
            align: "center",
          },
          {
            prop: "clientName",
            label: "分店/客户名称",
            minWidth: 155,
            align: "center",
          },
          {
            prop: "clientTypeName",
            label: "客户类型",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "belongShopName",
            label: "所属分店",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "orderQty",
            label: "订货总数",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "distributeQty",
            label: "预配数",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "diffQty",
            label: "差异数量",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "factDistributeQty",
            label: "实配数",
            minWidth: 120,
            align: "center",
            type: 'my-input',
            disabled: () => this.autoDistributeStatus == 3
          },
          {
            prop: "orderSortNo",
            label: "订货排名",
            minWidth: 120,
            align: "center",
          },
        ],
        list: [],
        listOld: [],
      },
    };
  },
  beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      try {
        vm.loading = true;
        // 访问组件实例 vm 中的数据和方法
        if (vm.$route.query.type == "Update" && vm.$route.query.lineId) {
          await vm.getList()
          console.log('autoDistributeStatus', vm.autoDistributeStatus);
        }
      } finally {
        vm.loading = false;
      }
    });
  },
  methods: {
    async handleEvent(type, row) {
      switch (type) {
        case "delDistribute":
          if (this.options2.check.length == 0)return this.$message.error("请选择要删除的分货对象！");
          let lineDetailIds = this.options2.check.map((item) => item.lineDetailId);
          await delDistributeObject({ lineDetailIds})
           this.$message.success("删除成功！");
           this.options2.check = [];
          await this.getList();
          break;
        case "addDistribute":
          this.dialogOptions = {
            title: "新增分货对象",
            width: 1250,
            show: false,
            type: "TreeAndTable"
          }
          this.dialogOptions.formData = this.$dialog({
            key: "addDistribute"
          });
          this.dialogOptions.formData.table.pagination = {
            ...this.dialogOptions.formData.table.pagination,
            lineId: this.$route.query.lineId,
          };
          this.dialogOptions.show = true
          break;
        case "calculate":
          if (this.autoDistributeStatus != 1) {
            return this.$message.error("已计算或已完成的单据，无法重新计算！");
          }
          //分货计算
          await calculateDistribute([{
            lineId: this.$route.query.lineId,
          }])
          await this.getList();
          this.$message.success("计算成功！");
          break;
        case "getQuit":
          // 关闭当前tab页签，打开新页签
          this.$tab.closeOpenPage({ path: "/produce/bill/autoDistribute" });
          break;
        case "submitForm":
          let arr = []
          this.options2.list.forEach(item => {
            arr.push({ lineDetailId: item.lineDetailId, factDistributeQty: item.factDistributeQty })
          })
          if (arr.length == 0) return this.$message.error("没有可修改的实配数!")
          await modifyFactDistributeQty(arr)
          this.$message.success("保存成功！");
          this.$tab.closeOpenPage({ path: "/produce/bill/autoDistribute" });
          break;
        case "accomplish":
          if (this.autoDistributeStatus != 1) {
            return this.$message.error("已计算或已完成的单据，无法要货加单！");
          }
          this.dialogOptions = {
            title: "要货加单",
            width: 1250,
            show: false,
            type: "AskGoodsAddOrders",
            goodsInfo: this.goodsInfo
          }
          this.dialogOptions.show = true
          break
        case "dialogChange":
          if (this.dialogOptions.type == "TreeAndTable") {
          let typeKeys = this.dialogOptions.formData.table.check.map(item => ({
              addClientId: item.storeId,
              addToLineId: this.$route.query.lineId
            }))
           await artificiallyAddDistributeObject(typeKeys)
           this.$message.success("新增成功！");
          }
          await this.getList();
          break;
        case "rowClick":
          try {
            this.loading = true;
            console.log('s', this.options1.radioObject);
            if (this.options1.radioObject) {
              this.options2.list = this.options2.listOld.filter(item => item.deliverLineId == this.options1.radioObject.lineId)
              if ( this.options2.list.length==0) {
              this.options1.radioObject = null
              this.options2.list = deepCopy(this.options2.listOld)
              }
            } else {
              this.options2.list = deepCopy(this.options2.listOld)
            }
          } finally {
            this.loading = false;
          }
          break;
        case 'resetSaasDistribute':
          try {
            console.log('e1');
            if (!this.loading) {
              this.loading = true;
            }
            await resetDistribute([{
              lineId: this.$route.query.lineId,
            }])
            console.log('e2');
            await this.getList();
            this.$message.success("重置成功！");
            console.log('e3');
          } catch (ee) {
            console.log('e', ee);
          } finally {
            if (this.loading) {
              this.loading = false;
            }
          }
          break;
        default:
          break;
      }
    },
    async getList() {
      try {
        if (!this.loading) {
          this.loading = true;
        }
        const res = await getCalculateDistributeDetail({ lineId: this.$route.query.lineId })
        this.autoDistributeStatus = res.data.autoDistributeStatus
        this.options1.list = res.data.deliverLines
        this.options2.list = deepCopy(res.data.calculateDetails)
        this.options2.listOld = deepCopy(res.data.calculateDetails)
        this.goodsInfo = res.data.goodsInfo
        this.goodsInfo.lineId = this.$route.query.lineId
        this.billInfo = {
          goodsName: res.data.goodsInfo.goodsName,
          unitEligibleQty: res.data.unitEligibleQty || 0,
          inferiorQty: res.data.inferiorQty || 0,
          unitQty: res.data.unitQty || 0,
        }
       await this.handleEvent('rowClick')
      } finally {
        if (this.loading) {
          this.loading = false;
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
#table-page {
  height: calc(100vh - 135px);
}
</style>
